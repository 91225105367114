import React, { useReducer, useCallback } from 'react';
import ScrollContext from './scrollContext';
import ScrollReducer, { UPDATE_SCROLL_POSITION } from './scrollReducer';

const initialState = {
  scrollX: 0,
  scrollY: 0
};

const ScrollState = ({ children }) => {
  const [state, dispatch] = useReducer(ScrollReducer, initialState);

  const updateScrollPosition = useCallback(async options => {
    requestAnimationFrame(() => {
      dispatch({
        type: UPDATE_SCROLL_POSITION,
        payload: options
      });
    })
  }, []);
  return (
    <ScrollContext.Provider
      value={{
        ...state,
        updateScrollPosition
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollState;
