import React, { useEffect, useRef } from 'react';
import gsap, { Elastic, Sine } from 'gsap';
const JumpingText = ({children}) => {
  const wrapperElRef = useRef(null);
  const setWrapperEl = (ref) => {
    wrapperElRef.current = ref;
  }
  useEffect(() => {
    const onMouseEnter = (e) => {
      const el = e.currentTarget;
      gsap.killTweensOf(el);
      gsap.to(el, {
        scaleY: 1.1,
        ease: Sine.easeOut,
        duration: 0.2,
        transformOrigin: "bottom"
      })
      gsap.to(el, {
        scaleY: 1,
        ease: Elastic.easeOut.config(1, 0.3),
        duration: 1,
        transformOrigin: "bottom",
        delay: 0.2
      })
    };
    const onMouseLeave = (e) => {
      const el = e.currentTarget;
      gsap.killTweensOf(el);
      gsap.to(el, {
        scaleY: 1,
        ease: Elastic.easeOut.config(1, 0.3),
        duration: 1,
        transformOrigin: "bottom"
      })
    };
    wrapperElRef.current.querySelectorAll('span').forEach(el => {
      gsap.set(el, {display: 'inline-block', verticalAlign: 'top'});
      el.addEventListener('mouseenter', onMouseEnter);
      // el.addEventListener('mouseleave', onMouseLeave);
    })
    return () => {
      wrapperElRef.current.querySelectorAll('span').forEach(el => {
        gsap.set(el, {clearProps: true});
        el.removeEventListener('mouseenter', onMouseEnter);
        // el.removeEventListener('mouseleave', onMouseLeave);
      })
    }
  }, []);
  return (
    <div ref={setWrapperEl}>
      {children.split('').map((char, idx) => (char === '\n'? <br/> : <span key={idx} dangerouslySetInnerHTML={{
        __html: char === ' '? '&nbsp;': char
      }} />))}
    </div>
  );
}

export default JumpingText;