import routes from 'globals/routes';
import React, { useEffect, useRef } from 'react';
import {Link, useLocation} from 'react-router-dom';

import './playgroundButton.css';

const PlaygroundButton = () => {
  const location = useLocation();
  const playgroundButtonElRef = useRef(null);
  // useEffect(() => {
  //   console.log(location.pathname);
  // }, [location]);
  return <div id="playgroundButton" className={location.pathname.substr(1)}>
    <Link ref={playgroundButtonElRef} to={routes.playground}></Link> 
  </div>;
}

export default PlaygroundButton;