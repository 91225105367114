import React, { useContext } from 'react';
import StatusContext from 'contexts/status/statusContext';
import './videoPopup.css';

const VideoPopup = () => {
  const {isPopupOpen, popupUrl, setPopupOpen} = useContext(StatusContext);
  const closeVideoPopup = () => {
    setPopupOpen({isPopupOpen: false});
  }
  return <div className={`videoPopup${isPopupOpen?' opened': ''}`}>
    <div className="closeButton" onClick={closeVideoPopup}></div>
    <div className="videoWrapper">
      <iframe src={popupUrl} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
    </div>
  </div>;
}
export default VideoPopup;