import React, { useEffect, useState } from 'react';
import {HashRouter, BrowserRouter, Switch, Route, Redirect, useLocation} from 'react-router-dom';
import asyncLoadingPage from 'components/asyncLoadingComponent.js';
import routes from 'globals/routes';
import CursorState from 'contexts/cursor/cursorState';
import ScrollState from 'contexts/scroll/scrollState';
import StatusState from 'contexts/status/statusState';
import DataState from 'contexts/data/dataState';
import Footer from 'containers/footer';
import ScrollComponent from 'components/scrollComponent';
import LoadingComponent from 'components/loadingComponent';
import Menu from 'containers/menu';
import Cursor from 'containers/cursor';
// import logo from './logo.svg';
import './App.css';
import PlaygroundButton from 'containers/playgroundButton';
import VideoPopup from 'containers/project/videoPopup';
// import ReactGA from 'react-ga';
import Analytics from 'react-router-ga';

const getRenderPropForRoute = (pageId) => {
  const AppComponent = asyncLoadingPage(pageId);
  // const AppComponent = templates[pageId];
  return (match) => { return (<AppComponent match={match} />) };
  
}
const App = () => {
  return (
    <DataState>
      <CursorState>
        <StatusState>
          <ScrollState>
            <div id="mobileCover">
              <img id="mobileLogo" src="./media/image/MG_logo.svg" />
              <div className="hints">Mobile version is coming soon!<br/>
              please refer to desktop<br/>version, thank you.</div>
              <div className="contact">
                <a href="mailto:info@morninggiantsstudio.com">info@morninggiantsstudio.com</a>
                <div className="address">Unit 1011, 10/F, 41 Heung Yip Road</div>
              </div>
            </div>
            <BrowserRouter basename="/">
              <Analytics id="UA-197153072-1">
                <Cursor />
                <Menu />
                <VideoPopup />
                <LoadingComponent />
                <ScrollComponent main={true}>
                  <Switch>
                    <Route path={routes.landing} render={getRenderPropForRoute('landing')} />
                    <Route path={routes.about} render={getRenderPropForRoute('about')} />
                    <Route path={routes.works} render={getRenderPropForRoute('works')} />
                    <Route path={routes.project} render={getRenderPropForRoute('project')} />
                    <Route path={routes.contact} render={getRenderPropForRoute('contact')} />
                    <Route path={routes.playground} render={getRenderPropForRoute('playground')} />
                    <Redirect to={routes.landing} />
                  </Switch>
                  {/* <Route path={new RegExp(`^(?!(${routes.contact})).*$`)}
                    component={() => <Footer />} 
                  /> */}
                  <Route render={({location}) => [routes.contact].includes(location.pathname)? null: <Footer />} />
                </ScrollComponent>
                <PlaygroundButton />
              </Analytics>
            </BrowserRouter>
          </ScrollState>
        </StatusState>
      </CursorState>
    </DataState>
  );
}

export default App;
