
import React from 'react';

import './smallSun2.css';

const SmallSun2 = ()=> {
  return <svg className="smallSun2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 200 200" style={{enableBackground: "new 0 0 200 200"}} xmlSpace="preserve">
    <g>
      <circle className="st0" cx="100" cy="100" r="25.06"/>
      <g>
        <path className="st1" d="M98.99,74.09l0.69-25.88c0-0.17,0.15-0.3,0.32-0.3c0.16,0,0.29,0.14,0.3,0.3l0.69,25.88
          c0.01,0.55-0.42,1.01-0.97,1.03c-0.55,0.01-1.01-0.42-1.03-0.97C98.99,74.13,98.99,74.11,98.99,74.09z"/>
      </g>
      <g>
        <path className="st1" d="M92.32,75.23l-6.03-25.18c-0.04-0.16,0.06-0.33,0.23-0.37c0.16-0.04,0.32,0.06,0.37,0.21l7.37,24.82
          c0.16,0.53-0.14,1.09-0.67,1.24c-0.53,0.16-1.09-0.14-1.24-0.67C92.32,75.27,92.32,75.25,92.32,75.23z"/>
      </g>
      <g>
        <path className="st1" d="M86.17,78.07L73.83,55.31c-0.08-0.15-0.03-0.34,0.12-0.42c0.14-0.08,0.32-0.03,0.41,0.11L87.9,77.07
          c0.29,0.47,0.14,1.09-0.33,1.38s-1.09,0.14-1.38-0.33C86.19,78.1,86.18,78.08,86.17,78.07z"/>
      </g>
      <g>
        <path className="st1" d="M80.96,82.39L63.15,63.6c-0.12-0.12-0.11-0.32,0.01-0.43c0.12-0.11,0.3-0.11,0.42,0l18.79,17.81
          c0.4,0.38,0.42,1.01,0.04,1.41S81.4,82.81,81,82.43C80.99,82.42,80.97,82.41,80.96,82.39z"/>
      </g>
      <g>
        <path className="st1" d="M77.05,87.92L54.99,74.38c-0.14-0.09-0.19-0.28-0.1-0.42c0.09-0.14,0.27-0.19,0.41-0.11l22.76,12.34
          c0.49,0.26,0.67,0.87,0.4,1.36c-0.26,0.49-0.87,0.67-1.36,0.4C77.09,87.94,77.07,87.93,77.05,87.92z"/>
      </g>
      <g>
        <path className="st1" d="M74.71,94.27L49.89,86.9c-0.16-0.05-0.26-0.22-0.21-0.38c0.05-0.16,0.21-0.25,0.37-0.21l25.18,6.03
          c0.54,0.13,0.87,0.67,0.74,1.21s-0.67,0.87-1.21,0.74C74.74,94.28,74.73,94.28,74.71,94.27z"/>
      </g>
      <g>
        <path className="st1" d="M74.09,101.01l-25.88-0.69c-0.17,0-0.3-0.15-0.3-0.32c0-0.16,0.14-0.29,0.3-0.3l25.88-0.69
          c0.55-0.01,1.01,0.42,1.03,0.97s-0.42,1.01-0.97,1.03C74.13,101.01,74.11,101.01,74.09,101.01z"/>
      </g>
      <g>
        <path className="st1" d="M75.23,107.68l-25.18,6.03c-0.16,0.04-0.33-0.06-0.37-0.23c-0.04-0.16,0.06-0.32,0.21-0.37l24.82-7.37
          c0.53-0.16,1.09,0.14,1.24,0.67c0.16,0.53-0.14,1.09-0.67,1.24C75.27,107.68,75.25,107.68,75.23,107.68z"/>
      </g>
      <g>
        <path className="st1" d="M78.07,113.83l-22.76,12.34c-0.15,0.08-0.34,0.03-0.42-0.12c-0.08-0.14-0.03-0.32,0.11-0.41l22.07-13.54
          c0.47-0.29,1.09-0.14,1.38,0.33s0.14,1.09-0.33,1.38C78.1,113.81,78.08,113.82,78.07,113.83z"/>
      </g>
      <g>
        <path className="st1" d="M82.39,119.04L63.6,136.85c-0.12,0.12-0.32,0.11-0.43-0.01c-0.11-0.12-0.11-0.3,0-0.42l17.81-18.79
          c0.38-0.4,1.01-0.42,1.41-0.04s0.42,1.01,0.04,1.41C82.42,119.01,82.41,119.03,82.39,119.04z"/>
      </g>
      <g>
        <path className="st1" d="M87.92,122.95l-13.54,22.07c-0.09,0.14-0.28,0.19-0.42,0.1c-0.14-0.09-0.19-0.27-0.11-0.41l12.34-22.76
          c0.26-0.49,0.87-0.67,1.36-0.4c0.49,0.26,0.67,0.87,0.4,1.36C87.94,122.91,87.93,122.93,87.92,122.95z"/>
      </g>
      <g>
        <path className="st1" d="M94.27,125.29l-7.37,24.82c-0.05,0.16-0.22,0.26-0.38,0.21c-0.16-0.05-0.25-0.21-0.21-0.37l6.03-25.18
          c0.13-0.54,0.67-0.87,1.21-0.74s0.87,0.67,0.74,1.21C94.28,125.26,94.28,125.27,94.27,125.29z"/>
      </g>
      <g>
        <path className="st1" d="M101.01,125.91l-0.69,25.88c0,0.17-0.15,0.3-0.32,0.3c-0.16,0-0.29-0.14-0.3-0.3l-0.69-25.88
          c-0.01-0.55,0.42-1.01,0.97-1.03s1.01,0.42,1.03,0.97C101.01,125.87,101.01,125.89,101.01,125.91z"/>
      </g>
      <g>
        <path className="st1" d="M107.68,124.77l6.03,25.18c0.04,0.16-0.06,0.33-0.23,0.37c-0.16,0.04-0.32-0.06-0.37-0.21l-7.37-24.82
          c-0.16-0.53,0.14-1.09,0.67-1.24c0.53-0.16,1.09,0.14,1.24,0.67C107.68,124.73,107.68,124.75,107.68,124.77z"/>
      </g>
      <g>
        <path className="st1" d="M113.83,121.93l12.34,22.76c0.08,0.15,0.03,0.34-0.12,0.42c-0.14,0.08-0.32,0.03-0.41-0.11l-13.54-22.07
          c-0.29-0.47-0.14-1.09,0.33-1.38s1.09-0.14,1.38,0.33C113.81,121.9,113.82,121.92,113.83,121.93z"/>
      </g>
      <g>
        <path className="st1" d="M119.04,117.61l17.81,18.79c0.12,0.12,0.11,0.32-0.01,0.43c-0.12,0.11-0.3,0.11-0.42,0l-18.79-17.81
          c-0.4-0.38-0.42-1.01-0.04-1.41s1.01-0.42,1.41-0.04C119.01,117.58,119.03,117.59,119.04,117.61z"/>
      </g>
      <g>
        <path className="st1" d="M122.95,112.08l22.07,13.54c0.14,0.09,0.19,0.28,0.1,0.42c-0.09,0.14-0.27,0.19-0.41,0.11l-22.76-12.34
          c-0.49-0.26-0.67-0.87-0.4-1.36c0.26-0.49,0.87-0.67,1.36-0.4C122.91,112.06,122.93,112.07,122.95,112.08z"/>
      </g>
      <g>
        <path className="st1" d="M125.29,105.73l24.82,7.37c0.16,0.05,0.26,0.22,0.21,0.38c-0.05,0.16-0.21,0.25-0.37,0.21l-25.18-6.03
          c-0.54-0.13-0.87-0.67-0.74-1.21s0.67-0.87,1.21-0.74C125.26,105.72,125.27,105.72,125.29,105.73z"/>
      </g>
      <g>
        <path className="st1" d="M125.91,98.99l25.88,0.69c0.17,0,0.3,0.15,0.3,0.32c0,0.16-0.14,0.29-0.3,0.3l-25.88,0.69
          c-0.55,0.01-1.01-0.42-1.03-0.97s0.42-1.01,0.97-1.03C125.87,98.99,125.89,98.99,125.91,98.99z"/>
      </g>
      <g>
        <path className="st1" d="M124.77,92.32l25.18-6.03c0.16-0.04,0.33,0.06,0.37,0.23c0.04,0.16-0.06,0.32-0.21,0.37l-24.82,7.37
          c-0.53,0.16-1.09-0.14-1.24-0.67c-0.16-0.53,0.14-1.09,0.67-1.24C124.73,92.32,124.75,92.32,124.77,92.32z"/>
      </g>
      <g>
        <path className="st1" d="M121.93,86.17l22.76-12.34c0.15-0.08,0.34-0.03,0.42,0.12c0.08,0.14,0.03,0.32-0.11,0.41L122.93,87.9
          c-0.47,0.29-1.09,0.14-1.38-0.33s-0.14-1.09,0.33-1.38C121.9,86.19,121.92,86.18,121.93,86.17z"/>
      </g>
      <g>
        <path className="st1" d="M117.61,80.96l18.79-17.81c0.12-0.12,0.32-0.11,0.43,0.01c0.11,0.12,0.11,0.3,0,0.42l-17.81,18.79
          c-0.38,0.4-1.01,0.42-1.41,0.04s-0.42-1.01-0.04-1.41C117.58,80.99,117.59,80.97,117.61,80.96z"/>
      </g>
      <g>
        <path className="st1" d="M112.08,77.05l13.54-22.07c0.09-0.14,0.28-0.19,0.42-0.1c0.14,0.09,0.19,0.27,0.11,0.41l-12.34,22.76
          c-0.26,0.49-0.87,0.67-1.36,0.4c-0.49-0.26-0.67-0.87-0.4-1.36C112.06,77.09,112.07,77.07,112.08,77.05z"/>
      </g>
      <g>
        <path className="st1" d="M105.73,74.71l7.37-24.82c0.05-0.16,0.22-0.26,0.38-0.21c0.16,0.05,0.25,0.21,0.21,0.37l-6.03,25.18
          c-0.13,0.54-0.67,0.87-1.21,0.74c-0.54-0.13-0.87-0.67-0.74-1.21C105.72,74.74,105.72,74.73,105.73,74.71z"/>
      </g>
    </g>
  </svg>;
}

export default SmallSun2;