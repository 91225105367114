export const SET_CURSOR_POSITION = 'SET_CURSOR_POSITION';
export const SET_CURSOR_IMAGE = 'SET_CURSOR_IMAGE';

export default (state, action) => {
  switch (action.type) {
    case SET_CURSOR_POSITION:
      return {
        ...state,
        x: action.payload.x,
        y: action.payload.y
      };
    case SET_CURSOR_IMAGE:
      return {
        ...state,
        cursor: action.payload
      };
    default:
      return state;
  }
};
