import React, { useReducer, useCallback, useEffect } from 'react';
import CursorContext from './cursorContext';
import CursorReducer, { SET_CURSOR_IMAGE, SET_CURSOR_POSITION } from './cursorReducer';

const initialState = {
  x: 0,
  y: 0,
  cursor: null
};
const cursorList = {
  "zip": "./media/icon/zip-regular.svg",
  "holiday": "./media/icon/history-solid.svg",
  "drag": "./media/icon/drag_btn.svg",
  "play": "./media/icon/Play_btn.svg",
}
const CursorState = ({ children }) => {
  const [state, dispatch] = useReducer(CursorReducer, initialState);
  const setCursorImage = useCallback(async options => {
    if (cursorList[options] === undefined) {
      document.body.style.cursor = "none";
      dispatch({
        type: SET_CURSOR_IMAGE,
        payload: null
      })
    } else {
      document.body.style.cursor = "none";
      dispatch({
        type: SET_CURSOR_IMAGE,
        payload: cursorList[options]
      })
    }
  }, []);
  const setCursorPosition = useCallback(async event => {
    requestAnimationFrame(() => dispatch({
      type: SET_CURSOR_POSITION,
      payload: {
        x: event.pageX,
        y: event.pageY
      }
    }))
  }, []);
  useEffect(() => {
    document.addEventListener("mousemove", setCursorPosition);
    return () => {
      document.removeEventListener("mousemove", setCursorPosition);
    }
  }, [setCursorPosition])
  return (
    <CursorContext.Provider
      value={{
        ...state,
        setCursorImage,
        // setCursorPosition
      }}
    >
      {children}
    </CursorContext.Provider>
  );
};

export default CursorState;
