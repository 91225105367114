export const SET_MENU_OPEN = 'SET_MENU_OPEN';
export const SET_POPUP_OPEN = 'SET_POPUP_OPEN';

export const SET_DATA_CAHCE = 'SET_DATA_CAHCE';
export const GET_DATA_CAHCE = 'GET_DATA_CAHCE';

export default (state, action) => {
  switch (action.type) {
    case SET_DATA_CAHCE:
      return {
        ...state,
        cache: new Map([...state.cache, action.payload])
      };
    case GET_DATA_CAHCE:
      return {
        ...state,
        isPopupOpen: action.payload.isOpen,
        popupUrl: action.payload.url
      };
    default:
      return state;
  }
};
