export const UPDATE_SCROLL_POSITION = 'UPDATE_SCROLL_POSITION';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_SCROLL_POSITION:
      return {
        ...state,
        scrollX: action.payload.scrollX,
        scrollY: action.payload.scrollY
      };
    default:
      return state;
  }
};
