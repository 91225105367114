import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import dataContext from 'contexts/data/dataContext';
import routes from 'globals/routes';
import gsap from 'gsap';

import './footer.css';

const Footer = () => {

  const {getPage} = useContext(dataContext);
  const [content, setContent] = useState(null);
  const countdownTimerElRef = useRef(null);
  const countdownTimerTimeout = useRef(null);
  const footerElRef = useRef(null);
  const backgroundCoverElRef = useRef(null);
  const hourNow = new Date().getHours();
  const nightMode = (hourNow < 6 || hourNow > 18);

  const [visible, setVisible] = useState(false);

  const {pathname} = useLocation();
  
  const [vidSrc, setVidSrc] = useState(null);
  const showCoverMask = (e) => {
    if (window.innerWidth > 767) {
      const {x,y,width,height} = e.currentTarget.getBoundingClientRect();
      // // if (maskTargetRef.current[2] < 100) {
      //   maskRef.current[0] = x;
      //   maskRef.current[1] = y;
      // // }
      // maskTargetRef.current[2] = 100;
      gsap.to(backgroundCoverElRef.current.querySelector('video'), {
        opacity: 0.4
      })
      gsap.to(
        backgroundCoverElRef.current,
        {
          clipPath: `circle(${Math.max(width, height) / 2}px at ${x + width / 2}px ${height / 2}px)`
        }
      );
    } else {
      gsap.set(backgroundCoverElRef.current.querySelector('video'), {
        opacity: ""
      })
      gsap.set(
        backgroundCoverElRef.current,
        {
          clipPath: ''
        }
      );
    }
  }
  const hideCoverMask = (e) => {
    // maskTargetRef.current[2] = 0;
    if (window.innerWidth > 767) {
      if (backgroundCoverElRef.current) {
        const {x,y,width,height} = e.currentTarget.getBoundingClientRect();
        gsap.to(backgroundCoverElRef.current.querySelector('video'), {
          opacity: 1
        })
        gsap.to(
          backgroundCoverElRef.current,
          {
            clipPath: `circle(${window.innerWidth}px at ${x + width / 2}px ${height / 2}px)`
          }
        );
      }
    }
  }
  const updateTimer = () => {
    const now = new Date();
    const next = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (now.getHours() >= 6? 1: 0), 6);
    const minRemain = Math.floor((next - now) / 1000 / 60);
    if (countdownTimerElRef.current) {
      countdownTimerElRef.current.innerText = ('00' + Math.floor(minRemain / 60)).substr(-2) + ' h ' + ('00' + (minRemain % 60)).substr(-2) + ' mins';
    }
    countdownTimerTimeout.current = setTimeout(updateTimer, 1000);
    return () => {
      clearTimeout(countdownTimerTimeout.current);
    }
  }
  useEffect(() => {
    (async _ => {
      const pageJson = await getPage(`footer`);
      setContent(pageJson);
    })()
  }, [getPage]);
  useEffect(() => {
    const stopTimer = updateTimer();
    if (backgroundCoverElRef.current) {
      hideCoverMask({
        currentTarget: countdownTimerElRef.current
      })
    }
    // maskDrawAnimation.current = requestAnimationFrame(drawMask);
    // footerElRef.current.addEventListener('mousemove', updateCoverMask);
    // footerElRef.current.addEventListener('mousemove', updateCoverMask);
    // footerElRef.current.addEventListener('mousemove', updateCoverMask);
    // countdownTimerTimeout.current = setTimeout(updateTimer, 1000);
    return () => {
      // clearTimeout(countdownTimerTimeout.current);
      // cancelAnimationFrame(maskDrawAnimation.current);
      // footerElRef.current.removeEventListener('mousemove', updateCoverMask);
      // footerElRef.current.removeEventListener('mousemove', updateCoverMask);
      // footerElRef.current.removeEventListener('mousemove', updateCoverMask);
      gsap.killTweensOf(backgroundCoverElRef.current);
      gsap.killTweensOf(backgroundCoverElRef.current.querySelector('video'));
      stopTimer();
    }
  }, [])
  useEffect(() => {
    if (content) {
      setVidSrc(
        nightMode?
          content['nightVideos'][Math.floor(content['nightVideos'].length * Math.random())]:
          content['morningVideos'][Math.floor(content['morningVideos'].length * Math.random())]
      );
    }
  }, [content]);
  useEffect(() => {
    setVisible(false);
    const reVisible = setTimeout(() => setVisible(true), 1000);
    return () => {
      clearTimeout(reVisible);
    }
  }, [pathname])
  return <div id="footer" className={`${visible? '': ' hide'}`} ref={footerElRef}>
    {content? <>
      <div className={`backgroundCover${nightMode? ' nightMode': ''}`}
        ref={backgroundCoverElRef}
      >
        {vidSrc && <video className="vid1" src={vidSrc} autoPlay loop muted playsInline />}
        {/* <video className="vid1" src="./media/video/footer1.mp4" autoPlay loop muted playsInline/> */}
      </div>
      <div className="contacts" onMouseEnter={showCoverMask} onMouseLeave={hideCoverMask}>
        <div className="name">{content['name']}</div>
        <a className="email" href={`mailto:${content['email']}`}>{content['email']}</a>
        <div className="address">{content['address']}</div>
        {/* <div className="phone">(852) 0000 0000</div> */}
      </div>
      <div className="countdown" onMouseEnter={showCoverMask} onMouseLeave={hideCoverMask}>
        <div className="timer" ref={countdownTimerElRef} />
        <div className="timerHints">til the next beautiful morning</div>
      </div>
      <div className="socialMedia" onMouseEnter={showCoverMask} onMouseLeave={hideCoverMask}>
        <div className="header">Follow us</div>
        <div className="mediaList">
          {content['instagram']?
            <a href={content['instagram']} target="_blank">
              <img src="./media/image/instagram.svg" />
            </a>:
            null
          }
          {content['youtube']?
            <a href={content['youtube']} target="_blank">
              <img src="./media/image/youtube.svg" />
            </a>:
            null
          }
          {content['vimeo']?
            <a href={content['vimeo']} target="_blank">
              <img src="./media/image/vimeo.svg" />
            </a>:
            null
          }
          {content['facebook']?
            <a href={content['facebook']} target="_blank">
              <img src="./media/image/facebook.svg" />
            </a>:
            null
          }
          {content['behance']?
            <a href={content['behance']} target="_blank">
              <img src="./media/image/behance.svg" />
            </a>:
            null
          }
        </div>
        {content['showjoinus'] === 1 && <div className="joinUs">
          <Link to={routes.contact}>
            Join MG Here -&gt;
          </Link>
        </div>}
      </div>
    </>
    : null}
  </div>
}

export default Footer;