import React, { useReducer, useCallback } from 'react';
import DataContext from './dataContext';
import DataReducer, {SET_DATA_CAHCE, GET_DATA_CAHCE} from './dataReducer';
import {apiBaseUrl} from 'globals/config';

import axios from 'axios';

const initialState = {
  cache: new Map()
};

const DataState = ({ children }) => {
  const [state, dispatch] = useReducer(DataReducer, initialState);
  const fetchNewPage = async (pageId) => {
    return ;
      // .then(res => res.json())
  }
  const getPage = useCallback(async page => {
    if (state.cache.has(page)) {
      return state.cache.get(page);
    } else {
      // const newPageJson = await axios.get(`${apiBaseUrl}/${page}.json`); // fetchNewPage(page);
      const newPageJson = await axios.get(`${apiBaseUrl}/${page}`); // fetchNewPage(page);
      dispatch({
        type: SET_DATA_CAHCE,
        payload: [
          page, newPageJson.data
        ]
      })
      return newPageJson.data;
    }
  }, [state]);

  const setPopupOpen = useCallback(async options => {
    requestAnimationFrame(() => {
      dispatch({
        type: '111',
        payload: options
      });
    })
  }, []);

  return (
    <DataContext.Provider
      value={{
        ...state,
        getPage,
        setPopupOpen
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataState;
