export const SET_MENU_OPEN = 'SET_MENU_OPEN';
export const SET_POPUP_OPEN = 'SET_POPUP_OPEN';
export const SET_IS_LOADING = 'SET_IS_LOADING';

export default (state, action) => {
  switch (action.type) {
    case SET_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: action.payload
      };
    case SET_POPUP_OPEN:
      return {
        ...state,
        isPopupOpen: action.payload.isOpen,
        popupUrl: action.payload.url
      };
      
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
};
