import React, { useEffect, useRef } from 'react';
import gsap, { Elastic, Sine } from 'gsap';
const JumpingLogo = ({className = '', fill = '#000000'}) => {
  const svgElRef = useRef(null);
  const setSvgEl = (ref) => {
    svgElRef.current = ref;
  }
  useEffect(() => {
    svgElRef.current.querySelectorAll('path').forEach(el => {
      el.addEventListener('mouseenter', () => {
        gsap.killTweensOf(el);
        gsap.to(el, {
          scaleY: 1.1,
          ease: Sine.easeOut,
          duration: 0.2,
          transformOrigin: "bottom"
        })
        gsap.to(el, {
          scaleY: 1,
          ease: Elastic.easeOut.config(1, 0.3),
          duration: 1,
          transformOrigin: "bottom",
          delay: 0.2
        })
      })
      // el.addEventListener('mouseleave', () => {
      //   gsap.killTweensOf(el);
      //   gsap.to(el, {
      //     scaleY: 1,
      //     ease: Elastic.easeOut.config(1, 0.3),
      //     duration: 1,
      //     transformOrigin: "bottom"
      //   })
      // })
    })
  }, []);
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="343 150 1242 750" style={{enableBackground:'new 0 0 1920 1080'}} xmlSpace="preserve"
      ref={setSvgEl}
      className={className}
    >
      <rect x="335" y="150" width="1250" height="750" fill="transparent"/>
      <g>
        <path fill={fill} d="M343.12,518.87l57.61,0.08c0.32,0,0.61-0.12,0.82-0.35c0.22-0.23,0.33-0.52,0.32-0.84l-10.67-258.7
          c0,0-0.05-3.21,0.03-5.15c0.11-2.66,0.69-2.86,0.86-3.04c0.75-0.81,1.77-1.28,2.87-1.32c2.17-0.09,4.04,1.54,4.27,3.7l27,264.72
          c0.06,0.58,0.54,1.02,1.13,1.02l31.15,0.05c0.58,0,1.07-0.43,1.14-1l31.34-264.81c0.13-1.1,0.68-2.07,1.54-2.76
          c0.87-0.68,1.95-0.99,3.04-0.86c2.16,0.25,3.73,2.15,3.64,4.34l-15.34,263.93c-0.02,0.31,0.09,0.62,0.31,0.85
          c0.22,0.23,0.51,0.36,0.83,0.36l60.76,0.09c0.3,0,0.58-0.11,0.8-0.33c0.21-0.21,0.33-0.49,0.33-0.79l0.48-325.16
          c0.01-0.91-0.23-1.13-1.12-1.13l-86.11-0.13c-0.61,0-1.07,0.44-1.1,1.06l-9.82,226.71c-0.06,1.34-0.63,2.58-1.62,3.48
          c-0.99,0.91-2.27,1.37-3.61,1.32c-2.63-0.12-4.77-2.29-4.8-4.91l-4.57-226.59c0-0.02,0-0.04,0-0.06c-0.04-0.6-0.52-1.06-1.11-1.06
          l-89.89-0.13c-0.3,0-0.58,0.12-0.8,0.33c-0.21,0.21-0.33,0.5-0.33,0.8L342,517.75C342,518.33,342.5,518.87,343.12,518.87z"
        />
        <path fill={fill} d="M582.67,248.8l-0.58,212.06c-0.06,21.54,4.99,37.26,15.03,46.74c10.06,9.5,28.63,14.36,55.2,14.43
          c18.6,0.05,33.19-2.1,43.37-6.38c10.04-4.22,17.13-10.74,21.09-19.35c4.02-8.74,6.07-20.53,6.11-35.05l0.58-212.06
          c0.04-14.52-1.95-26.33-5.92-35.09c-3.91-8.65-10.97-15.18-20.98-19.47c-10.16-4.34-24.74-6.56-43.33-6.62
          c-26.57-0.07-45.17,4.68-55.28,14.13C587.88,211.56,582.73,227.26,582.67,248.8z M655.09,227.35c2.17,0.09,3.91,1.86,3.96,4.03
          l5.79,236.3c0.15,6.78-5.24,12.41-12.02,12.56c-4.2,0.09-7.96-1.95-10.25-5.12c-1.4-1.95-2.25-4.32-2.31-6.9
          c-0.01-0.23,0-0.51,0.01-0.75l10.53-236.16c0.04-1.1,0.51-2.12,1.32-2.87C652.94,227.69,653.99,227.31,655.09,227.35z"
        />
        <path fill={fill} d="M758.2,517.54c0,0.3,0.11,0.58,0.33,0.8c0.22,0.22,0.5,0.34,0.8,0.33l56.53,0.27c0.62,0,1.13-0.48,1.14-1.1l4.68-147.23
          c0.13-4.16,3.62-7.43,7.78-7.3c3.96,0.14,7.17,3.34,7.3,7.31l4.33,147.34c0.02,0.62,0.52,1.1,1.13,1.11l59.89,0.29
          c0.9,0.01,1.25-0.19,1.3-0.26c0.04-0.05,0.1-0.33-0.1-0.94c-1.88-6.71-3.05-13.06-3.5-18.92c-0.44-5.83-0.64-13.71-0.6-23.44
          l0.4-83.41c0.05-10.99-1.97-19.4-6.01-24.99c-3.99-5.52-10.74-8.95-20.09-10.2l-1.07-0.14l0.03-5.79l0.91-0.24
          c9.06-2.37,15.81-7.3,20.05-14.65c4.31-7.46,6.52-16.83,6.57-27.87l0.28-57.49c0.1-20.61-4.54-35.8-13.8-45.14
          c-2.39-2.41-5.32-4.53-8.76-6.33c-9.89-5.18-24.07-7.84-42.37-7.92l-74.46-0.36c-0.62,0-1.13,0.5-1.13,1.12L758.2,517.54z
          M825.91,228.67c0.94-0.89,2.16-1.35,3.45-1.32c2.55,0.07,4.62,2.12,4.69,4.69l2.58,107.4c0.05,1.98-0.67,3.86-2.03,5.3
          c-1.36,1.44-3.2,2.26-5.18,2.32c-1.31,0.04-2.55-0.27-3.63-0.84c-2.3-1.21-3.91-3.59-3.99-6.37l0-0.36l2.61-107.45
          C824.44,230.75,824.98,229.55,825.91,228.67z"
        />
        <path fill={fill} d="M1102.79,517.68l-0.83-325.15c0-0.62-0.51-1.13-1.13-1.13h0l-40.82,0.1c-0.61,0-1.11,0.48-1.14,1.08l-11.96,259.1
          c-0.05,1.17-0.56,2.26-1.43,3.05c-0.87,0.8-2.01,1.25-3.18,1.15c-2.32-0.11-4.17-2.01-4.2-4.35l-3.49-258.85
          c0-0.62-0.52-1.13-1.14-1.13c0,0,0,0,0,0l-92.85,0.24c-0.3,0-0.59,0.12-0.8,0.33c-0.21,0.21-0.33,0.5-0.33,0.8l0.83,325.16
          c0,0.62,0.51,1.12,1.13,1.12h0l57.97-0.15c0.3,0,0.59-0.12,0.81-0.34c0.21-0.22,0.33-0.5,0.33-0.81l-2.14-259.07
          c-0.01-1.18,0.44-2.29,1.27-3.13c0.83-0.84,1.93-1.31,3.11-1.32c0.01,0,0.02,0,0.04,0c2.31,0,4.24,1.8,4.4,4.11l17.63,259.42
          c0.04,0.6,0.54,1.06,1.14,1.06h0l75.67-0.19C1102.28,518.82,1102.79,518.3,1102.79,517.68z"
        />
        <path fill={fill} d="M1202.72,519.03c0.43,0,0.74-0.09,0.9-0.23c0.14-0.15,0.23-0.47,0.23-0.9l0.1-325.15c0-0.43-0.08-0.75-0.23-0.9
          c-0.15-0.15-0.47-0.23-0.9-0.23l-63.15-0.02c-0.33,0-0.58,0.03-0.75,0.12c-0.29,0.15-0.38,0.45-0.38,1.01l-0.1,325.15
          c0,0.43,0.08,0.75,0.23,0.9c0.15,0.14,0.47,0.23,0.9,0.23L1202.72,519.03z"
        />
        <path fill={fill} d="M1299.01,519.04c0.3,0,0.59-0.12,0.81-0.33c0.19-0.21,0.33-0.51,0.33-0.82l-1.73-259.06c-0.01-1.03,0.34-2.01,0.98-2.8
          c0.09-0.11,0.19-0.22,0.29-0.33c0.83-0.84,1.93-1.31,3.11-1.31c2.32-0.02,4.27,1.79,4.43,4.11l17.21,259.45
          c0.04,0.6,0.54,1.06,1.14,1.06l75.68-0.07c0.3,0,0.58-0.12,0.8-0.33c0.23-0.21,0.33-0.5,0.33-0.8l-0.31-325.15
          c0-0.62-0.51-1.13-1.13-1.13l-40.83,0.04c-0.61,0-1.11,0.48-1.14,1.08l-12.38,259.08c-0.11,2.43-2.18,4.31-4.62,4.2
          c-2.32-0.11-4.17-2.02-4.2-4.35l-3.07-258.86c-0.01-0.62-0.52-1.13-1.14-1.12l-92.85,0.09c-0.3,0-0.58,0.12-0.8,0.33
          c-0.24,0.24-0.33,0.5-0.33,0.8l0.31,325.16c0,0.3,0.11,0.59,0.33,0.8c0.21,0.22,0.5,0.33,0.8,0.33L1299.01,519.04z"
        />
        <path fill={fill} d="M1521.97,329.14l5.59,140.21c0.13,3.28-1,6.41-3.25,8.83c-2.23,2.41-5.26,3.81-8.54,3.94c-3.28,0.13-6.41-1.02-8.83-3.25
          c-2.41-2.23-3.81-5.26-3.94-8.54c-0.01-0.26-0.01-0.55-0.01-0.8l6.16-236.27c0.03-1.2,0.53-2.31,1.4-3.14
          c0.87-0.83,2.01-1.27,3.21-1.23c2.35,0.06,4.28,1.95,4.37,4.31l3.51,87.83l55.78-0.13c0.43,0,0.75-0.08,0.89-0.23
          c0.17-0.16,0.23-0.47,0.23-0.9l-0.16-70.22c-0.03-14.84-1.93-26.72-5.63-35.32c-3.64-8.45-10.58-14.88-20.6-19.1
          c-10.18-4.29-25.08-6.44-44.31-6.4c-24.7,0.06-42.81,5.07-53.81,14.89c-10.97,9.79-16.5,25.35-16.45,46.25l0.53,230.91
          c0.03,15.01,3.04,25.9,8.94,32.37c5.87,6.44,16.11,9.69,30.45,9.66c11.29-0.03,20.06-2.49,26.08-7.33
          c5.97-4.79,9.28-11.01,9.87-18.48l0.09-1.13l5.38-0.01l5.95,22.57c0,0,0-0.01,0-0.01c0.04-0.06,1.19,0.98,8.71,0.97l44.3-0.1
          c0.89,0,1.13-0.23,1.12-1.13l-0.43-188.03c0-0.89-0.23-1.12-1.13-1.12L1521.97,329.14z"
        />
        <path fill={fill} d="M429.96,699.11l6.21,140.19c0.15,3.28-0.97,6.41-3.21,8.84c-2.21,2.42-5.24,3.84-8.52,3.98c-3.28,0.15-6.42-0.99-8.84-3.21
          c-2.42-2.22-3.84-5.25-3.98-8.53c-0.01-0.26-0.01-0.55-0.01-0.8l5.11-236.29c0.02-1.2,0.52-2.32,1.38-3.15
          c0.87-0.83,2.01-1.27,3.21-1.25c2.35,0.05,4.28,1.94,4.39,4.29l3.9,87.81l55.78-0.37c0.43,0,0.75-0.09,0.89-0.23
          c0.17-0.16,0.23-0.47,0.22-0.9l-0.47-70.22c-0.1-14.84-2.05-26.72-5.79-35.29c-3.68-8.43-10.64-14.83-20.68-19.01
          c-10.2-4.24-25.11-6.33-44.34-6.2c-24.7,0.16-42.79,5.25-53.74,15.13c-10.92,9.84-16.38,25.42-16.24,46.32l1.55,230.91
          c0.1,15.01,3.16,25.89,9.09,32.33c5.89,6.41,16.16,9.62,30.49,9.52c11.29-0.08,20.05-2.58,26.05-7.45
          c5.95-4.82,9.23-11.05,9.78-18.53l0.08-1.13l5.38-0.03l6.05,22.55c0,0,0-0.01,0-0.01c0.04-0.06,1.19,0.98,8.72,0.93l44.3-0.3
          c0.89-0.01,1.13-0.23,1.12-1.14l-1.26-188.02c-0.01-0.89-0.24-1.12-1.13-1.11L429.96,699.11z"
        />
        <path fill={fill} d="M589.53,562.18l-63.15-0.02c-0.37,0-0.62,0.04-0.79,0.15c-0.25,0.16-0.33,0.45-0.33,0.99l-0.07,325.15
          c0,0.43,0.08,0.75,0.23,0.9c0.15,0.15,0.47,0.23,0.9,0.23l63.15,0.01c0.43,0,0.74-0.08,0.9-0.23c0.15-0.15,0.23-0.47,0.23-0.9
          l0.08-325.16c0-0.43-0.08-0.75-0.23-0.9C590.28,562.26,589.96,562.17,589.53,562.18L589.53,562.18z"
        />
        <path fill={fill} d="M750.05,562.23l-104.14-0.15c-0.34-0.02-0.77,0.53-1.15,1.41l-23.58,325.16l-0.11,0.22c0.52,0.43,0.9,0.59,1.26,0.59
          l60.4,0.09c-0.01-0.4,0.31-5.85,0.33-6.22l4.91-74.67c0.32-4.81,4.48-8.46,9.29-8.14c4.42,0.29,7.85,3.81,8.14,8.11l5.21,74.68
          c0.03,0.44,0.32,5.85,0.31,6.28l60.79,0.09c0.37,0.01,0.73-0.15,1.14-0.46l-0.11-0.22l0.1-0.37l-21.65-324.99
          C750.81,562.76,750.38,562.23,750.05,562.23z M699.91,624.12l4.31,155.28c0.12,4.37-3.33,8.01-7.7,8.13
          c-4.37,0.12-8.01-3.33-8.13-7.7c0-0.09,0-0.22,0-0.31l2.02-155.33c0.03-2.62,2.18-4.73,4.81-4.7
          C697.77,619.53,699.83,621.58,699.91,624.12z"
        />
        <path fill={fill} d="M861.76,888.99c0.3,0,0.59-0.12,0.81-0.33c0.19-0.21,0.33-0.51,0.33-0.82l-1.73-259.06c-0.01-1.03,0.34-2.01,0.98-2.8
          c0.09-0.11,0.19-0.22,0.29-0.33c0.83-0.84,1.93-1.31,3.11-1.31c2.32-0.02,4.27,1.79,4.43,4.11l17.21,259.45
          c0.04,0.6,0.54,1.06,1.14,1.06l75.68-0.07c0.3,0,0.58-0.12,0.8-0.33c0.23-0.21,0.33-0.5,0.33-0.8l-0.31-325.15
          c0-0.62-0.51-1.13-1.13-1.13l-40.83,0.04c-0.61,0-1.11,0.48-1.14,1.08l-12.38,259.08c-0.11,2.43-2.18,4.31-4.62,4.2
          c-2.32-0.11-4.17-2.02-4.2-4.35l-3.07-258.86c-0.01-0.62-0.52-1.13-1.14-1.12l-92.85,0.09c-0.3,0-0.58,0.12-0.8,0.33
          c-0.24,0.24-0.33,0.5-0.33,0.8l0.31,325.16c0,0.3,0.11,0.59,0.33,0.8c0.21,0.22,0.5,0.33,0.8,0.33L861.76,888.99z"
        />
        <path fill={fill} d="M1082.72,618.29l19.61-0.02c0.43,0,0.75-0.08,0.9-0.23c0.14-0.15,0.23-0.46,0.22-0.9l-0.05-54.66
          c0-0.43-0.08-0.75-0.23-0.9c-0.16-0.16-0.46-0.23-0.9-0.23l-106.5,0.1c-0.43,0-0.76,0.08-0.9,0.23c-0.15,0.15-0.23,0.47-0.23,0.9
          l0.05,54.66c0,0.89,0.23,1.12,1.13,1.12l19.61-0.02l0.26,269.37c0,0.43,0.08,0.75,0.23,0.9c0.14,0.13,0.46,0.23,0.9,0.23
          l65.03-0.06c0.43,0,0.75-0.08,0.9-0.23c0.15-0.15,0.23-0.47,0.23-0.9L1082.72,618.29z"
        />
        <path fill={fill} d="M1207.46,700.1c0,0.36,0.04,0.61,0.13,0.78c0.15,0.29,0.45,0.38,1,0.38l26.22,0l35.93,0.01c0.43,0,0.75-0.08,0.9-0.23
          c0.15-0.15,0.23-0.47,0.23-0.9l0.01-89.07c0.01-19.85-6.61-33.77-19.64-41.35c-13.25-7.71-30.64-11.62-51.69-11.63
          c-21.05,0-38.44,3.91-51.69,11.61c-13.04,7.58-19.66,21.49-19.66,41.35l-0.01,97.55c0,13.42,2.42,22.74,7.19,27.65
          c4.77,4.92,14.62,7.41,29.28,7.41l12.57,0l0,0l14.29,0c5.02,0,7.38-0.37,10.88,1.66c1.77,1.03,4.36,2.55,5.02,7.3l3.56,60.19
          l1.25,24.6c0.09,2.28-0.46,4.47-1.54,6.41c-0.47,0.86-1.02,1.68-1.71,2.42c-2.23,2.41-5.26,3.81-8.54,3.94
          c-3.28,0.13-6.41-1.02-8.83-3.25c-2.41-2.23-3.81-5.26-3.94-8.54c-0.01-0.26-0.01-0.54-0.01-0.8l2.41-85.81l-60.75-0.01
          c-0.43,0-0.75,0.08-0.9,0.23c-0.15,0.15-0.23,0.46-0.23,0.89l-0.01,86.25c-0.01,19.86,6.61,33.76,19.64,41.34
          c13.25,7.71,30.64,11.62,51.69,11.63c21.05,0,38.44-3.91,51.69-11.61c13.04-7.57,19.65-21.49,19.66-41.35l0.02-95.19
          c0-11.53-3.03-20.19-9.03-25.72c-6.02-5.57-16.04-8.39-29.8-8.4l-25.17-0.01h-2.63c-3.24,0-7.26,0.29-10.8-3.39
          c-1.46-1.52-1.92-4.02-2.04-6.02l0,0l2.42-98.75c0.03-1.2,0.53-2.31,1.4-3.14c0.34-0.32,0.74-0.53,1.15-0.73
          c0.64-0.32,1.33-0.53,2.07-0.51c1.74,0.04,3.22,1.09,3.93,2.58l0-0.01c0.2,0.64,0.43,1.4,0.44,1.74
          C1203.77,600.99,1207.46,700.1,1207.46,700.1z"
        />
      </g>
    </svg>
  );
}

export default JumpingLogo;