import React, { useContext } from 'react';

import CursorContext from 'contexts/cursor/cursorContext';

import './cursor.css';

const Cursor = () => {
  const {x, y, cursor} = useContext(CursorContext);
  return <div style={{
    position: 'fixed',
    transform: `translate(${x}px, ${y}px)`,
    pointerEvents: "none",
    zIndex: 101
  }}>
    {cursor ? <img 
      style={{ 
        width: 100, 
        height: 100, 
        objectFit: "contain",
        transform: `translate(-50%, -50%)`,
      }} 
      className="customCursor"
      src={cursor} 
    />: 
    <div className="defaultCursor" />
  }
  </div>;
}

export default Cursor;