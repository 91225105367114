import React, { useEffect, useState, useRef, useContext } from 'react';
import {Link, useLocation} from 'react-router-dom';

import StatusContext from 'contexts/status/statusContext';
import CursorContext from 'contexts/cursor/cursorContext';

import routes from 'globals/routes';
import * as THREE from 'three';
import gsap from 'gsap';
import JumpingText from 'components/jumpingText';
import JumpingLogo from 'components/jumpingLogo';
import SmallSun2 from 'components/smallSun2';

import './menu.css';

const Menu = () => {
  const {isMenuOpen, setMenuOpen} = useContext(StatusContext);
  const {setCursorImage} = useContext(CursorContext);
  const {pathname} = useLocation();
  const backgroundWrapperRef = useRef(null);
  const mousePos = useRef([0.5, 0.5]);
  
  const setBackgroundWrapper = (ref) => {
    backgroundWrapperRef.current = ref;
  }
  const initBackground = () => {
    
    const scene = new THREE.Scene();
    // const camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 0.001, 1000);
    const camera = new THREE.OrthographicCamera(0, window.innerWidth / 100, window.innerHeight / 100, 0, 0.01, 10);
    // const camera = new THREE.OrthographicCamera(0, window.innerWidth / 50, window.innerHeight / 50, 0, 0.01, 10);

    const renderer = new THREE.WebGLRenderer({ 
      antialias: true, 
      alpha: true
    });
    renderer.setClearColor(0xFFFFFF, 1);
    // retina screen too lag
    renderer.setPixelRatio(1);
    renderer.shadowMap.enabled = true;
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.domElement.classList.add('sunCanvas');
    backgroundWrapperRef.current.appendChild(renderer.domElement);
    const spotLight = new THREE.SpotLight( 0xCAD656, 0.85 );
    spotLight.angle = Math.PI / 3;
    spotLight.penumbra = 0.3;
    spotLight.decay = 2;
    spotLight.distance = 10;
    scene.add(spotLight);
    // const spotLight2 = new THREE.SpotLight( 0xCAD656, 0.8 );
    const spotLight2 = new THREE.SpotLight( 0xCAD656, 0.8 );
    spotLight2.angle = Math.PI;
    spotLight2.penumbra = 1;
    spotLight2.decay = 3;
    spotLight2.distance = 25;
    scene.add(spotLight2);
    const bgMaterial = new THREE.MeshPhongMaterial();
    const bgGeometry = new THREE.PlaneGeometry(1, 1);

    const bg = new THREE.Mesh(bgGeometry, bgMaterial);
    bg.position.set(window.innerWidth / 200, window.innerHeight / 200, 0);
    bg.scale.set(window.innerWidth / 100, window.innerHeight / 100, 1);
    scene.add( bg );
    const sphere = new THREE.Object3D();
    sphere.position.set(window.innerWidth * 0.5 / 100, window.innerHeight * 0.2 / 100, 0);
    camera.position.set(0, 0, 2);
    spotLight.position.set(window.innerWidth / 100 * 0.5, 0, 2);
    spotLight2.position.set(window.innerWidth / 100 * 0.5, -1.5, 1);
    scene.add( sphere );
    spotLight.target = sphere;
    const light2Target = new THREE.Object3D();
    light2Target.position.set(window.innerWidth * 0.5 / 100, window.innerHeight * 0.2 / 100, 0);
    spotLight2.target = light2Target;
    scene.add( light2Target );
    window.scene = scene; 
    window.camera = camera; 
    const render = () => {
      renderer.clear();
      renderer.render(scene, camera);
    }
    const update = () => {
      gsap.to(sphere.position, {
        x: -window.innerWidth * 0.2 / 100 * Math.cos((mousePos.current[0]) * Math.PI / 2 + Math.PI / 4) + window.innerWidth * 0.5 / 100,
        y: window.innerWidth * 0.2 / 100 * Math.sin((mousePos.current[0]) * Math.PI / 2 + Math.PI / 4),
        duration: 1
      })
      gsap.to(spotLight, {
        decay: 2 - mousePos.current[1]
      })
      // gsap.to()
    }
    renderer.setAnimationLoop(() => {
      update();
      render();
    });
    const onResize = () => {
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.right = window.innerWidth / 100;
      camera.top = window.innerHeight / 100;
      camera.updateProjectionMatrix();
      sphere.position.y = window.innerHeight * 0.25 / 100;
      sphere.scale.set(window.innerWidth * 0.08 / 100, window.innerWidth * 0.08 / 100, window.innerWidth * 0.08 / 100);
      bg.position.set(window.innerWidth / 200, window.innerHeight / 200, 0);
      bg.scale.set(window.innerWidth / 100, window.innerHeight / 100, 1);
      spotLight.position.set(window.innerWidth / 100 * 0.5, 0, 2);
      spotLight2.position.set(window.innerWidth / 100 * 0.5, -1.5, 1);
    }
    window.addEventListener('resize', onResize);
    const intensityTimeline = gsap.timeline({repeat: -1, yoyo: true});
    intensityTimeline.add(
      gsap.to(spotLight, {
        distance: 5,
        duration: 5
      })
    )
    return () => {
      console.log('unloaded');
      renderer.setAnimationLoop(null);
      backgroundWrapperRef.current.removeChild(renderer.domElement);
      window.removeEventListener('resize', onResize);
      intensityTimeline.clear();
      bgGeometry.dispose();
      bgMaterial.dispose();
      renderer.dispose();
    };
  }
  const onMouseMove = (e) => {
    mousePos.current = [e.pageX / window.innerWidth, 1 - e.pageY / window.innerHeight];
  }
  useEffect(() => {
    const unload = initBackground();    
    document.addEventListener('mousemove', onMouseMove);
    return () => {
      unload();      
      document.removeEventListener('mousemove', onMouseMove);
    }
  }, [])
  return <div id="menu" 
    // onMouseEnter={() => {
    //   setCursorImage("zip");
    // }}
    // onMouseLeave={() => {
    //   setCursorImage(null);
    // }}
  >
    <div className={`menuButton ${isMenuOpen? "opened": ""}`}
      onClick={() => {
        setMenuOpen(!isMenuOpen);
      }}
    >
    <div className="line"></div>
    </div>
    <div className="logo">
      <Link onClick={() => setMenuOpen(false) } to={routes.landing}>
        <img src="./media/image/new_logo.svg"/>
      </Link>
    </div>
    <div id="menuCover" className={isMenuOpen? "opened": ""}>
      <div className="menuBackground" ref={setBackgroundWrapper}>
        <svg viewBox="-10 -10 120 120" className="rotatingBrand">
          <path id="curve" d="M50,50 m35,0 a35,35 0 1,1 -70,0 a35,35 0 1,1 70,0" fill="transparent" />
          <g>
            <text fill="currentColor" fontSize="24">
              <textPath href="#curve">
                MORNING GIANTS MORNING GIANTS
              </textPath>
            </text>
            <animateTransform attributeName="transform" begin="0s" dur="60s" type="rotate" from="0 50 50" to="-360 50 50" repeatCount="indefinite"/>
          </g>
        </svg>
      </div>
      
      {/* <JumpingLogo className="innerLogo" /> */}
      <div className="innerLogo">
        <img src="./media/image/new_logo.svg"/>
      </div>
      <nav>
        <ul>
          <li>
            <Link onClick={() => setMenuOpen(false) } className={`${pathname === routes.landing? 'active': ''}`} to={routes.landing}>
              <SmallSun2 />
              <JumpingText>
                Home
              </JumpingText>
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpen(false) } className={`${pathname === routes.about? 'active': ''}`} to={routes.about}>
              <SmallSun2 />
              <JumpingText>
                Giants
              </JumpingText>
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpen(false) } className={`${pathname === routes.works? 'active': ''}`} to={routes.works}>
              <SmallSun2 />
              <JumpingText>
                Project
              </JumpingText>
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpen(false) } className={`${pathname === routes.contact? 'active': ''}`} to={routes.contact}>
              <SmallSun2 />
              <JumpingText>
                Contact
              </JumpingText>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>;
}

export default Menu;